<template>
	<h1>Form Label Input</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormDropdown from '@/components/FormDropdown'

export default {
	components: { FormDropdown },
	data() {
		return {
			myChoice: '',
			theLabels: [
				{
					value: 'round',
					display: 'round',
				},
				{
					value: 'pepperoni',
					display: 'pepperoni',
				},
				{
					value: 'deep-dish',
					display: 'deep dish',
				}
			],

			// Used in the demo below:
			theMediums: [
				{
					value: 'oil',
					display: 'oil',
					type: 'medium',
				},
				{
					value: 'gouache',
					display: 'gouache',
					type: 'medium',
				},
				{
					value: 'canvas',
					display: 'canvas',
					type: 'medium-substrate', // &#60;-- Display darker
				},
			],
		}
	}
}</code></pre>
	</div>
	<div class="text-block bump-b-30">
		<h3>Demo</h3>

		<br />
		<div class="structured-form two-cols">
			<FormLabels v-model="myChoice" :labels="theLabels" />
			<FormLabels v-model="myChoice" :labels="theMediums" :isMedium="true" />
		</div>
	</div>
</template>

<script>
import FormLabels from '@/components/FormLabels'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsLabels',
	components: { FormLabels },
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Labels',
		})
	},
	data() {
		return {
			myChoice: '',
			theLabels: [
				{
					value: 'round',
					display: 'round',
				},
				{
					value: 'pepperoni',
					display: 'pepperoni',
				},
				{
					value: 'deep-dish',
					display: 'deep dish',
				},
			],
			theMediums: [
				{
					value: 'oil',
					display: 'oil',
					type: 'medium',
				},
				{
					value: 'gouache',
					display: 'gouache',
					type: 'medium',
				},
				{
					value: 'canvas',
					display: 'canvas',
					type: 'medium-substrate',
				},
			],
			html: [
				'<FormLabels',
				'	v-model="myChoice"				// Data model',
				'	:labels="theLabels"				// Labels to preload',
				'	:medium="true"					// Changes into medium input',
				'',
				'									// Shared:',
				'	:mT/mR/mB/mL="30"				// Set single margin',
				'	:margin="[10,10,10,10]"			// Set all margins at once',
				'	:width="300"					// Default width is 250',
				'/>',
			].join('\n'),
		}
	},
}
</script>

<style scoped lang="scss"></style>
